import React, {CSSProperties, MouseEvent, ReactNode} from 'react';
import {defineMessages, MessageDescriptor, useIntl} from 'react-intl';
import {Box, ListItemText, MenuItem, MenuItemProps, Typography} from '@mui/material';

import {StyledCheckbox} from '@components/checkbox/StyledCheckbox';
import {MemoizedChip} from '@components/chip/ChipRenderer';
import {Chip} from '@components/chip/Chips';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {CustomIcon} from '@components/icons';
import {useGeneralSelectClasses} from '@components/select/selectStyle';

export type MultiSelectItemProps = Pick<MenuItemProps, 'onClick'> & {
    label: string | MessageDescriptor;
    value: string;
    isSelected?: boolean;
    chipType?: string;
    chipValue?: string;
    style?: CSSProperties;
    className?: string;
    children?: ReactNode;
    checkbox?: boolean;
    suboption?: boolean;
    hasSuboptions?: boolean;
    divider?: boolean;
    isExpanded?: boolean;
    onExpand?: () => void;
    onCollapse?: () => void;
};

const localized = defineMessages({
    group: {
        id: 'MultiSelectItemWithSublist_group',
        defaultMessage: 'Group',
    },
});

export const MultiSelectItem = React.forwardRef<HTMLLIElement, MultiSelectItemProps>(
    (
        {
            chipValue,
            label,
            isSelected,
            chipType,
            checkbox = true,
            suboption,
            hasSuboptions,
            divider,
            isExpanded,
            onExpand,
            onCollapse,
            ...rest
        }: MultiSelectItemProps,
        ref
    ) => {
        const {classes: generalSelectClasses, cx} = useGeneralSelectClasses();
        const {formatMessage} = useIntl();
        function handleTextClick(event: MouseEvent) {
            if (hasSuboptions) {
                event.stopPropagation();
                if (isExpanded) {
                    onCollapse();
                } else {
                    onExpand();
                }
            }
        }
        const checkboxComponent = checkbox ? <StyledCheckbox checked={isSelected} data-testid="checkbox" /> : null;
        const primaryComponent = chipType ? (
            <MemoizedChip chipName={chipType} value={(Array.isArray(chipValue) ? chipValue[0] : chipValue) as string} cursorPointer />
        ) : (
            <Typography variant="button" className={generalSelectClasses.listItemTextTypography}>
                <LocalizedText label={label} />
            </Typography>
        );
        const suboptionsExistsMarker = hasSuboptions ? (
            <>
                <Box
                    className={cx(isExpanded ? CustomIcon.ArrowUp : CustomIcon.ArrowDown, generalSelectClasses.expander)}
                    component="span"
                />
                <Chip
                    fillWidth={false}
                    chipType={ChipType.Prefix}
                    label={formatMessage(localized.group)}
                    chipVariant={ChipVariant.Grey}
                    className={generalSelectClasses.chip}
                />
            </>
        ) : null;

        return (
            <>
                <MenuItem
                    className={cx(
                        generalSelectClasses.menuItem,
                        suboption && generalSelectClasses.suboption,
                        divider && generalSelectClasses.menuItemDivider,
                        !checkbox && generalSelectClasses.cursor
                    )}
                    data-testid={`${chipValue}_menuItem`}
                    ref={ref}
                    {...rest}
                >
                    {checkboxComponent}
                    <div onClick={handleTextClick} className={generalSelectClasses.menuSelectItemContent}>
                        <ListItemText
                            className={cx(generalSelectClasses.listItemText, hasSuboptions && generalSelectClasses.groupListItemText)}
                            disableTypography
                            primary={primaryComponent}
                        />
                        {suboptionsExistsMarker}
                    </div>
                </MenuItem>
            </>
        );
    }
);
